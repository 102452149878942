import { FC } from 'react';

import {
  Box,
  Button,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  faBroomWide,
  faBrowser,
  faDiscDrive,
  faInfoCircle,
  faPlayCircle,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InfoModal: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  return (
    <>
      <IconButton
        aria-label="Help"
        onClick={onOpen}
        icon={<FontAwesomeIcon icon={faInfoCircle} size="lg" fixedWidth />}
        sx={{ bg: 'transparent' }}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        preserveScrollBarGap={true}
        isCentered={true}
        closeOnOverlayClick={false}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Before your event</ModalHeader>
          <ModalBody>
            <VStack
              direction="column"
              spacing={5}
              divider={<StackDivider borderColor="whiteAlpha.400" />}
            >
              <HStack alignItems="center" spacing={5}>
                <Box color="gray">
                  <FontAwesomeIcon icon={faBrowser} size="3x" fixedWidth />
                </Box>
                <Box>
                  <Text fontWeight="bold" mb={1}>
                    Check your browser
                  </Text>
                  <Text lineHeight={1.6} fontSize="sm">
                    Make sure you are using the latest version of Google Chrome.{' '}
                    <Link
                      href="https://www.google.com/chrome/dr/download/?brand=YTUH&gclid=Cj0KCQjw3eeXBhD7ARIsAHjssr-0QWppMvMnYqhoTxqafFiU0JqCiLdgojq51E768f_mHGQJCB61jXMaAoa5EALw_wcB&gclsrc=aw.ds"
                      target="_blank"
                      color="purple.500"
                    >
                      Download or update
                    </Link>
                  </Text>
                </Box>
              </HStack>
              <HStack alignItems="center" spacing={5}>
                <Box color="gray">
                  <FontAwesomeIcon icon={faDiscDrive} size="3x" fixedWidth />
                </Box>
                <Box>
                  <Text fontWeight="bold" mb={1}>
                    Make some space
                  </Text>
                  <Text lineHeight={1.6} fontSize="sm">
                    We recommend that your computer has up to 100GB of free
                    space.
                  </Text>
                </Box>
              </HStack>
              <HStack alignItems="center" spacing={5}>
                <Box color="gray">
                  <FontAwesomeIcon icon={faBroomWide} size="3x" fixedWidth />
                </Box>
                <Box>
                  <Text fontWeight="bold" mb={1}>
                    Clear your cache
                  </Text>
                  <Text lineHeight={1.6} fontSize="sm">
                    Clearing fixes certain problems, like loading or formatting.
                    Follow these{' '}
                    <Link
                      href="https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DDesktop"
                      target="_blank"
                      color="purple.500"
                    >
                      steps
                    </Link>
                    .
                  </Text>
                </Box>
              </HStack>
              <HStack alignItems="center" spacing={5}>
                <Box color="gray">
                  <FontAwesomeIcon icon={faPlayCircle} size="3x" fixedWidth />
                </Box>
                <Box>
                  <Text fontWeight="bold" mb={1}>
                    Watch the demo
                  </Text>
                  <Text lineHeight={1.6} fontSize="sm">
                    See a{' '}
                    <Link
                      href="https://vimeo.com/725820305"
                      target="_blank"
                      color="purple.500"
                    >
                      short video
                    </Link>{' '}
                    for an intro on how to use the Kinema Offline Player.
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="gray"
              width="100%"
              color="whiteAlpha.800"
              mb={4}
              textTransform="none"
              onClick={onClose}
              _hover={{
                bg: 'purple.500',
                color: 'whiteAlpha.800',
              }}
            >
              Got it!
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InfoModal;
